<template>
  <div class="">
    <Modal
      @close="closeModalonIcon"
      :isCenter="false"
      size="xl"
      :isStatic="true"
      title="Edit Email"
      ref="flowEdit"
      id="flowEdit"
    >
      <div class="emailContentContainer  d-flex flex-column w-100">
        <div class="">
          <div v-if="isLoading">
            <div class="row w-100">
              <div class="col-md-5">
                <div
                  class="d-flex flex-column align-items-center w-100"
                  style="gap: 16px"
                >
                  <div
                    class="bg-preload"
                    style="height: 40px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div
                    class="bg-preload mt-2"
                    style="height: 40px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div
                    class="bg-preload mt-2"
                    style="height: 40px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div
                    class="bg-preload mt-2"
                    style="height: 40px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div
                    class="bg-preload mt-2"
                    style="height: 40px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div
                  class="bg-preload"
                  style="height: 400px; width: 100%; border-radius: 4px"
                >
                  <div class="into-preload"></div>
                </div>
              </div>
            </div>
          </div>

          <div  v-else-if="toggleToEmailEdit">
            <div
              class="d-flex h-100 align-items-between justify-content-between flex-column w-100"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="flow-content mb-4">Flow Content</div>
                  <div
                    class="d-flex flex-column align-items-center w-100"
                    style="gap: 16px"
                  >
                    <div class="w-100">
                      <label for="name" class="d-flex gap align-items-center">
                        <span class="text-uppercase">Name</span>

                        <span
                          class="text-danger"
                          style="text-transform: lowercase"
                        >
                          *{{ errors.name }}
                        </span>
                      </label>
                      <input
                        type="text"
                        name=""
                        v-model.trim="editFlowStepData.name"
                        id="name"
                        class="quizell-form-control w-100 px-2"
                      />
                    </div>
                    <div class="w-100">
                      <label
                        for="subject-line"
                        class="d-flex gap align-items-center"
                      >
                        <span class="text-uppercase">Subject Line</span>

                        <span
                          class="text-danger"
                          style="text-transform: lowercase"
                        >
                          *{{ errors.subject }}
                        </span>
                      </label>
                      <input
                        type="text"
                        name=""
                        v-model.trim="editFlowStepData.subject"
                        id="subject-line"
                        class="quizell-form-control w-100 px-2"
                      />
                    </div>
                    <div class="w-100">
                      <label
                        for="preview-text"
                        class="d-flex gap align-items-center"
                      >
                        <span class="text-uppercase">preview text</span>

                        <span
                          class="text-danger"
                          style="text-transform: lowercase"
                        >
                          *{{ errors.preview_text }}
                        </span>
                      </label>
                      <input
                        type="text"
                        name=""
                        v-model.trim="editFlowStepData.preview_text"
                        id="preview-text"
                        class="quizell-form-control w-100 px-2"
                      />
                    </div>
                    <div class="w-100">
                      <label
                        for="sender-name"
                        class="d-flex gap align-items-center"
                      >
                        <span class="text-uppercase">Sender Name</span>

                        <span
                          class="text-danger"
                          style="text-transform: lowercase"
                        >
                          *{{ errors.sender_name }}
                        </span>
                      </label>
                      <input
                        type="text"
                        name=""
                        v-model.trim="editFlowStepData.sender_name"
                        id="sender-name"
                        class="quizell-form-control w-100 px-2"
                      />
                    </div>
                    <div class="w-100">
                      <label
                        for="sender-email-address"
                        class="d-flex text-uppercase gap align-items-center"
                      >
                        <span class="text-uppercase">Sender Email Address</span>

                        <span
                          class="text-danger"
                          style="text-transform: lowercase"
                        >
                          *{{ errors.sender_email_address }}
                        </span>
                      </label>
                      <div
                        v-if="isLoadingSenderEmail"
                        class="bg-preload mt-2"
                        style="height: 40px; width: 100%; border-radius: 4px"
                      >
                        <div class="into-preload"></div>
                      </div>
                      <select
                        v-else
                        name=""
                        id="sender-email-address"
                        v-model="editFlowStepData.sender_email_address"
                        class="quizell-form-control w-100 px-2"
                      >
                        <option value="">Select a Sender</option>
                        <option
                          :value="sender.from_email"
                          v-for="sender in verifiedSenders"
                          :key="sender.id"
                        >
                          {{ sender.from_email }}
                        </option>
                      </select>
                    </div>
                    <div class="link-add-new-email" @click="openWindow()">
                      Add new sender
                    </div>
                  
                  </div>
                </div>
                <div class="col-md-7">
                  <div
                    class="d-flex h-100 w-100"
                    v-if="!checkIfEmailTemplateDataExist"
                  >
                    <div class="card w-100">
                      <div
                        class="card-body h-100 w-100"
                        style="position: relative"
                      >
                      <div class="" style="position: absolute; right: 20px">
                      <button
                       
                        class="btn customTemplateActionBtn"
                        v-b-tooltip.hover
                        title="Add Template"
                        @click="openModal"
                      >
                      <b-icon icon="columns-gap"></b-icon>
                      </button>
                    </div>
                        <div
                          v-if="isEmailTemplateDataLoading"
                          class="d-flex h-100 justify-content-center flex-column align-items-center"
                        >
                          <div style="text-align: center">
                            <b-icon
                              icon="arrow-clockwise"
                              animation="spin"
                              font-scale="4"
                            ></b-icon>
                          </div>

                          <div style="text-align: center">
                            <div
                              class=""
                              style="font-size: 16px; font-weight: 500"
                            >
                              Fetching Email Preview Data
                            </div>
                            <div class="" style="font-size: 12px">
                              Hang tight. This may take a while!
                            </div>
                          </div>
                        </div>

                        <div
                          v-else
                          class="d-flex h-100 justify-content-center flex-column align-items-center"
                          style="cursor: pointer"
                          @click="openModal"
                        >
                          <div class="icon mb-4">
                            <svg
                              width="39"
                              height="42"
                              viewBox="0 0 39 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.5 0H4.5C2.28 0 0.5 1.78 0.5 4V36C0.5 38.22 2.28 40 4.5 40H20.12C19.06 38.18 18.5 36.1 18.5 34C18.5 33.34 18.56 32.66 18.66 32H4.5V28H20.12C21.04 26.4 22.32 25 23.86 24H4.5V20H28.5V22.16C29.16 22.06 29.84 22 30.5 22C31.16 22 31.84 22.06 32.5 22.16V12L20.5 0ZM18.5 14V3L29.5 14H18.5ZM28.5 26V32H22.5V36H28.5V42H32.5V36H38.5V32H32.5V26H28.5Z"
                                fill="#FFC000"
                              />
                            </svg>
                          </div>
                          <div style="text-align: center">
                            <div
                              class="drag-drop-title mb-2"
                              style="font-size: 16px; font-weight: 500"
                            >
                              Drag and Drop Email Editor
                            </div>
                            <div class="" style="font-size: 12px">
                              Create an email using our drag and drop editor.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="position-relative">
                    <div class="" style="position: absolute; right: 20px">
                      <div class="d-flex gap">
                        <button
                       
                        class="btn customTemplateActionBtn"
                        v-b-tooltip.hover
                        title="Change Template"
                        @click="openModal"
                      >
                      <b-icon icon="columns-gap"></b-icon>
                      </button>
                      <button
                        v-if="checkIfEmailTemplateDataExist"
                        class="btn customTemplateActionBtn"
                        v-b-tooltip.hover
                        title="Edit Design"
                        @click="navigatetoEmailEditor"
                      >
                        <b-icon icon="pencil" class=""></b-icon>
                      </button>
                      
                    </div>
                    </div>
                    <EmailTemplatePreview
                      :PreviewHtmlData="getEmailTemplateHtmlData"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex my-2 justify-content-end">
                <Button
                  :is-loading="isSaveLoading"
                  :disabled="!isSaveValid"
                  @click="updateFlowEmailStep"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div class="" v-else>
            <div v-if="!EmailTemplatePreviewMode">
              <ul class="d-flex align-items-center menu mb-4 px-0">
                <button class="btn goBackBtn" @click="toggleToEmailEdit = true">
                  <b-icon icon="arrow-left" font-scale="1" class=""></b-icon>
                  Go Back
                </button>
                <li
                  @click="SaveTemplateToggle = 'saved'"
                  :class="SaveTemplateToggle == 'saved' ? 'active' : ''"
                >
                  Saved Templates
                </li>
                <li
                  @click="SaveTemplateToggle = 'quizell'"
                  :class="SaveTemplateToggle == 'quizell' ? 'active' : ''"
                >
                  Quizell Templates
                </li>
              </ul>
              <div v-if="SaveTemplateToggle == 'saved'">
                <div>
                  <!-- Email Skeleton Loader Start -->
                  <div class="row" v-if="isEmailTemplatesLoading">
                    <div class="col-12 col-md-4 mb-2" v-for="n in 3" :key="n">
                      <div class="w-100">
                        <EmailTemplateSkeleton />
                      </div>
                    </div>
                  </div>
                  <!-- Email Skeleton Loader End -->
                  <div v-else>
                    <div class="row" v-if="emailTemplates.length">
                      <div
                        class="col-12 col-md-4 mb-3 "
                        v-for="(emailTemplate, index) in emailTemplates"
                        :key="index"
                      >
                        <SingleEmailTemplateComp
                          :emailTemplate="emailTemplate"
                          :templateMode="`saved`"
                          @chosenEmailTemplateId="chosenEmailTemplateId($event)"
                          @previewEmailTemplateId="
                            initiatePreviewTemplate($event)
                          "
                        />
                      </div>
                    </div>
                    <template v-else>
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="height: 50vh"
                      >
                        <EmptyTable
                          @click="navigateToCreateTemplate"
                          :showButton="true"
                          btnTitle="Create Template"
                        >
                          <template #title> No template found </template>
                          <template #description>
                            No Save Templates found! Create New template from
                            our collections of professional templates
                          </template>
                        </EmptyTable>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div v-else>
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 50vh"
                >
                  <EmptyTable
                    @click="navigateToCreateTemplate"
                    :showButton="true"
                    btnTitle="Create template"
                  >
                    <template #title> No template found </template>
                    <template #description>
                      Create New template from our collections of professional
                      templates
                    </template>
                  </EmptyTable>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="">
                <button class="btn goBackBtn" @click="ClosePreviewMode">
                  <b-icon icon="arrow-left" font-scale="1" class=""></b-icon>
                  Go Back
                </button>

                <div class="mt-2">
                  <EmailTemplatePreview
                    :previewEmailTemplate="previewEmailTemplate"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Modal Start -->

          <!-- Modal End -->
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import EmailTemplateSkeleton from "../Email/Components/EmailTemplateSkeleton.vue";
import axios from "axios";
import Button from "../../Layout/Button.vue";
import { isMatch } from "lodash";
import Modal from "../../Layout/Modal.vue";
import SingleEmailTemplateComp from "../Email/Components/SingleEmailTemplateComp.vue";
import EmailTemplatePreview from "../Email/Components/EmailTemplatePreview.vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import EmptyTable from "../../Layout/EmptyTable.vue";
export default {
  props: {
    contentId: String,
    id: String,
  },
  components: {
    Button,
    Modal,
    EmailTemplateSkeleton,
    SingleEmailTemplateComp,
    EmptyTable,
    EmailTemplatePreview,
  },
  data() {
    return {
      toggleToEmailEdit: false,
      isLoadingSenderEmail: false,
      newWindow: null,
      checkInterval: null,
      windowUrl: "/manage/emails/verified-sender-details?return=flow",
      isLoading: false,
      isSaveLoading: false,
      verifiedSenders: [],
      errors: {
        name: "",
        subject: "",
        preview_text: "",
        sender_name: "",
        sender_email_address: "",
      },
      SaveTemplateToggle: "saved",
      isReplayToEmail: false,
      selectedEmailTemplateId: null,
      EmailTemplatePreviewMode: false,
      emailTemplates: [],
      // unlayerEmailTemplates: [],
      isEmailTemplatesLoading: false,
      isUnlayerEmailTemplatesLoading: false,
      previewEmailTemplate: null,

      editFlowStepData: {},
      isEmailTemplateDataLoading: false,
      firstTimeData: null,
    };
  },

  methods: {
    async closeModalonIcon() {
      if (!isMatch(this.firstTimeData, this.editFlowStepData)) {
        const checkConfirmation = confirm(
          "Do you want to save the unsaved changes to your flow content?"
        );
        if (checkConfirmation) {
          if (this.validateEmailDetails()) {
            await this.updateFlowEmailStep();
          }
        } else {
          this.error = {};
          this.close();
        }
      } else {
        this.close();
      }

      // this.close()
    },
    open() {
      if (this.contentId) {
        this.getSenderEmails();
        this.getDetailEmailContent(this.contentId);
      }
      this.$refs.flowEdit.open();
      this.SaveTemplateToggle='saved'
      this.toggleToEmailEdit=true;
    },
    close() {
      this.$refs.flowEdit.closeStatic();
      
    },
    ...mapMutations(["updateCurrEmailDesignEdit", "resetCurrEmailDesignEdit"]),
   
    async navigatetoEmailEditor() {
      if (!(await this.updateFlowEmailStep(false))) {
        return false;
      }
      let obj = {
        redirectPath: this.$route.path,
        designData: {
          design_json: this.editFlowStepData.design_json,
          design_html: this.editFlowStepData.email_html,
          template_id: this.editFlowStepData.template_id,
        },
      };

      await this.updateCurrEmailDesignEdit(obj);

      let finalURL =
        window.location.origin +
        `/EmailEditor/${this.editFlowStepData.template_id}?editCustomDesign=true&EmailEditId=${this.contentId}`;

      window.open(finalURL, "_self");
    },

    ...mapActions(["fetchData"]),
    openModal() {
      this.emailTemplates = [];
      // this.unlayerEmailTemplates = [];
      this.toggleToEmailEdit = false;

      this.getSavedEmailTemplates();
      // this.getQuizellEmailTemplates();
  
    },
    closeModal() {
      this.ClosePreviewMode();
      this.$refs.emailContentModal.close();
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateEmailDetails() {
      this.errors = {}; // Clear previous errors
      // Validation logic
      if (!this.editFlowStepData.name) {
        this.errors.name = "required";
      }
      if (!this.editFlowStepData.subject) {
        this.errors.subject = "required";
      }
      if (!this.editFlowStepData.preview_text) {
        this.errors.preview_text = "required";
      }
      if (!this.editFlowStepData.sender_name) {
        this.errors.sender_name = "required";
      }
      if (!this.editFlowStepData.sender_email_address) {
        this.errors.sender_email_address = "required";
      }

      if (Object.keys(this.errors).length === 0) {
        return true;
      }
      this.$toasted.show("Please fill all the required fields.", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
      return false;
    },

    async getEmailTemplatePreviewData(id) {
      try {
        this.isEmailTemplateDataLoading = true;
        let data = {
          template_id: id,
          image: 1,
          html: 1,
          json: 1,
          is_unlayer_template: 0,
        };
this.toggleToEmailEdit=true;
        const response = await axios.post("/api/v1/email/templates/show", data);

        if (response.status == 200) {
          this.editFlowStepData.design_image = response.data.data.image;
          this.editFlowStepData.design_json = JSON.stringify(
            response.data.data.design
          );
          this.editFlowStepData.email_html = response.data.data.html;
          // this.emailPreviewData = response?.data?.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isEmailTemplateDataLoading = false;
      }
    },

    chosenEmailTemplateId(obj) {
      this.editFlowStepData.template_id = obj.data.template_id;

      this.editFlowStepData.design_image = null;
      this.editFlowStepData.design_json = null;
      this.editFlowStepData.email_html = null;

      this.getEmailTemplatePreviewData(obj.data.id);
   
    },

    initiatePreviewTemplate(obj) {
      this.EmailTemplatePreviewMode = true;
      this.previewEmailTemplate = obj;
    },

    ClosePreviewMode() {
      this.EmailTemplatePreviewMode = false;
      this.previewEmailTemplate = null;
    },
    navigateToCreateTemplate() {
       this.SaveTemplateToggle='saved'
      this.toggleToEmailEdit=true;
      window.open('/manage/emails/create-email-templates', '_blank');
    },
    async getSavedEmailTemplates() {
      try {
        this.isEmailTemplatesLoading = true;
        let data = {
          per_page: 20,
          page: 1,
        };
        const response = await this.fetchData({
          axiosMethod: "get",
          resource: "email_templates",
          method: "list",
          params: { params: data },
        });

        if (response.status == "success") {
          this.emailTemplates = response.data.email_templates.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isEmailTemplatesLoading = false;
      }
    },
    async getQuizellEmailTemplates() {
      try {
        this.isUnlayerEmailTemplatesLoading = true;
        let data = {
          per_page: 10,
          page: 1,
        };
        const response = await axios.get("/unlayer/email/templates", {
          params: data,
        });

        if (response.status == 200) {
          this.unlayerEmailTemplates = response?.data?.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isUnlayerEmailTemplatesLoading = false;
      }
    },
    async getDetailEmailContent(id) {
      try {
        this.isLoading = true;
        const response = await this.fetchData({
          axiosMethod: "get",
          resource: "flow",
          method: "stepEmailData",
          id: id,
        });

        if (response.status == "success") {
          this.editFlowStepData = response.data;
          if (
            this.isCustomDesignEditComplete &&
            this.getCurrEmailDesignEditData?.designData
          ) {
            this.editFlowStepData.design_json =
              this.getCurrEmailDesignEditData.designData.design_json;
            this.editFlowStepData.email_html =
              this.getCurrEmailDesignEditData.designData.design_html;
            this.editFlowStepData.template_id =
              this.getCurrEmailDesignEditData.designData.template_id;

            this.$toasted.show("Updated Custom Design", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }

          this.firstTimeData = JSON.parse(
            JSON.stringify(this.editFlowStepData)
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateFlowEmailStep(isOnEmailEditor = true) {
      // check if Emails are valid
      if (!this.validateEmailDetails()) return false;
   
      try {
        this.isSaveLoading = true;

        const response = await this.fetchData({
          axiosMethod: "put",
          resource: "flow",
          method: "updateStepEmail",
          id: this.editFlowStepData.uuid,
          params: this.editFlowStepData,
        });

        if (response.status == "success") {
          this.$toasted.show("Updated Successfully!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          this.$emit('triggerFetchFlow')
          if (isOnEmailEditor) {
            this.$emit("change");
          }
        const query = this.$route.query;
        if (Object.keys(query).length > 0) {  this.$router.push({ query: {} }); }
         this.close();
            return true;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error;
        }
      } finally {
        this.isSaveLoading = false;
      }
    },
    async getSenderEmails() {
      this.isLoadingSenderEmail = true;
      let response = await this.fetchData({
        axiosMethod: "get",
        resource: "verified_senders",
        method: "list",
        params: { params: { verified_senders: 1 } },
      });
      if (response && response.status == "success") {
        this.verifiedSenders = response.data.data;
      }
      this.isLoadingSenderEmail = false;
    },
    openWindow() {
      this.newWindow = window.open(this.windowUrl, "_blank");
      this.checkInterval = setInterval(this.checkWindowStatus, 500);
      window.addEventListener("message", this.handleMessage);
    },
    checkWindowStatus() {
      if (this.newWindow.closed) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
        this.handleWindowClosed();
        window.removeEventListener("message", this.handleMessage);
      }
    },
    handleMessage(event) {
      if (event.origin !== new URL(this.windowUrl).origin) {
        return;
      }

      if (event.data === "close") {
        if (this.newWindow && !this.newWindow.closed) {
          this.newWindow.close();
        }
        this.handleWindowClosed();
      }
    },
    handleWindowClosed() {
      this.getSenderEmails();
    },
  },
  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
    window.removeEventListener("message", this.handleMessage);
    this.resetCurrEmailDesignEdit();
  },

  mounted() {},

  computed: {
    ...mapGetters(["getCurrEmailDesignEditData"]),
    isSaveValid() {
      return this.editFlowStepData.name !== "" &&
        this.editFlowStepData.preview_text !== "" &&
        this.editFlowStepData.reply_to_email_address !== "" &&
        this.editFlowStepData.sender_email_address !== "" &&
        this.editFlowStepData.sender_name !== "" &&
        this.editFlowStepData.subject !== "" &&
        this.editFlowStepData.template_id &&
        this.editFlowStepData.design_json &&
        this.editFlowStepData.email_html
        ? true
        : false;
    },

    getEmailTemplateHtmlData() {
      return this.editFlowStepData.email_html
        ? this.editFlowStepData.email_html
        : null;
    },

    checkIfEmailTemplateDataExist() {
      return this.editFlowStepData.template_id &&
        this.editFlowStepData.email_html &&
        this.editFlowStepData.design_json
        ? true
        : false;
    },
    getParentFlowId() {
      return this.id;
    },
    getModalTitle() {
      return this.EmailTemplatePreviewMode
        ? `<span class='px-4'>Preview Email Template</span>`
        : `<span class='px-4'>Choose Email Template</span>`;
    },
    isCustomDesignEditComplete() {
      return Object.entries(this.$route.query).length &&
        this.$route.query.completedCustomDesign
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.emailContentContainer {
}

.contentContainer {
  min-height: calc(100% - 60px);
}

.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3s ease all;
  background-color: transparent;
}

.quizell-form-control:focus-visible {
  border: none !important;
  outline: none;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
label {
  color: #73738d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-transform: uppercase;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.quizell-dropdown >>> a.btn {
  background: transparent !important;
  color: #4d1b7e !important;
  border: 1px solid #4d1b7e !important;
}
.quizell-dropdown >>> button {
  background: #4d1b7e !important;
  color: white;
  /* border: 1px solid #4d1b7e!important; */
}

.quizell-dropdown >>> ul li a:active {
  background: #4d1b7e !important;
  color: white;
  /* border: 1px solid #4d1b7e!important; */
}

.TemplatesTabs >>> ul li a {
  background: transparent;
  color: #4d1b7e;
  font-weight: bold;
}
.TemplatesTabs >>> ul li a.active {
  background: #4d1b7e;
  color: #fff;
}

.goBackBtn {
  color: #73738d;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
  padding: 0px;
}

.customTemplateActionBtn {
  padding: 8px;
  border-radius: 8px;
  background: #f6f6f8;
  color: #73738d;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  border: none;
  font-weight: 600;
}
.customTemplateActionBtn:hover {
  color: var(--primary-color);
}
</style>
<style>
#flowEdit .modal-dialog {
  position: absolute;
  margin: 0px;
  right: 0px;
  max-width: 100%;
  width: 91%;
  height: 100vh;
}
#flowEdit .modal-dialog .modal-content {
  border-radius: 0px;
}
.flow-content {
  color: var(--Black, #18191c);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}
.drag-drop-title {
  color: #161e34;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
}
.drag-drop-title + div {
  color: #7c8290;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.card {
  border-radius: 12px;
  border: 1px dashed #d2d8e0;
}
.link-add-new-email {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  text-decoration-line: underline;
  text-align: left;
  cursor: pointer;
  flex: 1;
  width: 100%;
}
.menu {
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
  text-decoration: none;
  list-style-type: none;
}
.menu li {
  color: #73738d;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}
.menu li.active {
  color: var(--primary-color);
}
.menu li.active:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -15px;
  height: 1px;
  /* margin-bottom: 20px; */
  width: 100%;
  background: var(--primary-color);
}
</style>

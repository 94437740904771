<template>
  <div>
    <div
      @click.stop="
        chooseTemplate(
          emailTemplate,
          templateMode == 'saved' ? `saved` : `unlayer`
        )
      "
      class="singleEmailTemplateContainer cursor-pointer"
    >
      <div v-if="templateMode == 'saved'">
        <LazyImage
          :src="emailTemplate.design_image"
          :key="emailTemplate.design_image"
          style="
            height: 250px;
            width: 100%;
            aspect-ratio: 16 /9;
            object-fit: contain;
          "
          alt=""
        />
      </div>
      <div v-else>
        <LazyImage
          :src="emailTemplate.image"
          :key="emailTemplate.image"
          style="
            height: 250px;
            width: 100%;
            aspect-ratio: 16 /9;
            object-fit: contain;
          "
          alt=""
        />
      </div>

      <div
        class="mt-3 px-2 d-flex align-items-center justify-content-between pb-3"
      >
        <p class="templateName m-0">{{ emailTemplate.name }}</p>
        <div class="d-flex gap">
          <div class="" title="Select Template" @click.stop="
        chooseTemplate(
          emailTemplate,
          templateMode == 'saved' ? `saved` : `unlayer`
        )
      ">
            <svg
              width="21px"
              height="21px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M4 12.6111L8.92308 17.5L20 6.5"
                 stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
        
        <div
          @click="
            previewEmailTemplate(
              emailTemplate.id,
              templateMode == 'saved' ? `saved` : `unlayer`
            )
          "
          style="cursor: pointer"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_508_1755)">
              <path
                d="M1.5 10C1.5 10 4.83333 3.33334 10.6667 3.33334C16.5 3.33334 19.8333 10 19.8333 10C19.8333 10 16.5 16.6667 10.6667 16.6667C4.83333 16.6667 1.5 10 1.5 10Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M10.6667 12.5C12.0474 12.5 13.1667 11.3807 13.1667 10C13.1667 8.61929 12.0474 7.5 10.6667 7.5C9.28595 7.5 8.16667 8.61929 8.16667 10C8.16667 11.3807 9.28595 12.5 10.6667 12.5Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_508_1755">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.666656)"
                ></rect>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["emailTemplate", "templateMode"],
  methods: {
    chooseTemplate(data, mode) {
      let obj = {
        data: data,
        mode: mode,
      };
      this.$emit("chosenEmailTemplateId", obj);
    },
    previewEmailTemplate(id, mode) {
      let obj = {
        id: id,
        mode: mode,
      };
      this.$emit("previewEmailTemplateId", obj);
    },
  },
};
</script>

<style scoped>
.singleEmailTemplateContainer {
  cursor: pointer;
  box-shadow: rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.customBtn {
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 4px;
  background: #b3afb6;
  border: none;
  outline: none;
}

.templateName {
  color: var(--gray-28);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>
